import { SidebarType } from '../components/Sidebar/sidebarUtils'
import { TradeformType, TradeType } from '../model'
import {
  SetDataDisclaimerOpen,
  SetDropsearchFavoriteAnimationActive,
  SetHeaderFavoriteAnimationActive,
  SetNotificationsOpen,
  SetOmniboxSearchOpen,
  SetOpenModalVerify,
  SetOpenSidebar,
  SetOverviewOpen,
  SetProviderMenuOpen,
  SetSeasonPassOpen,
  SetSettingsOpen,
  SetTokenProfileVisibilityFlag,
  SetTradeformType,
  SetTraderProfileOpen,
  UIActions,
} from '../reducers/uiReducer'

export const setOmniboxSearchOpen = (
  value: boolean,
  openedIndirectly?: boolean
): SetOmniboxSearchOpen => ({
  type: UIActions.setOmniboxSearchOpen,
  payload: { value, openedIndirectly },
})

export const setHeaderFavoriteAnimationActive = (
  value: boolean
): SetHeaderFavoriteAnimationActive => ({
  type: UIActions.setHeaderFavoriteAnimationActive,
  payload: value,
})

export const setDropsearchFavoriteAnimationActive = (
  value: boolean
): SetDropsearchFavoriteAnimationActive => ({
  type: UIActions.setDropsearchFavoriteAnimationActive,
  payload: value,
})

export const setSidebarOpen = (value: SidebarType): SetOpenSidebar => ({
  type: UIActions.setSidebarOpen,
  payload: value,
})

export const setTradeformType = (value: TradeformType): SetTradeformType => ({
  type: UIActions.setTradeformType,
  payload: value,
})

export const setSettingsOpen = (value: boolean): SetSettingsOpen => ({
  type: UIActions.setSettingsOpen,
  payload: value,
})

export const setDataDisclaimerOpen = (value: boolean): SetDataDisclaimerOpen => ({
  type: UIActions.setDataDisclaimerOpen,
  payload: value,
})

export const setNotificationsOpen = (value: boolean): SetNotificationsOpen => ({
  type: UIActions.setNotificationsOpen,
  payload: value,
})

export const setOverviewOpen = (value: boolean): SetOverviewOpen => ({
  type: UIActions.setOverviewOpen,
  payload: value,
})

export const setModalVerifyOpen = (value?: TradeType): SetOpenModalVerify => ({
  type: UIActions.setOpenModalVerify,
  payload: value,
})

export const setTokenProfileVisibilityFlag = (payload: boolean): SetTokenProfileVisibilityFlag => ({
  type: UIActions.setTokenProfileVisibilityFlag,
  payload,
})

export const setTraderProfileOpen = (payload: boolean): SetTraderProfileOpen => ({
  type: UIActions.setTraderProfileOpen,
  payload,
})

export const setSeasonPassOpen = (value: boolean): SetSeasonPassOpen => ({
  type: UIActions.setSeasonPassOpen,
  payload: value,
})

export const setProviderMenuOpen = (value: boolean): SetProviderMenuOpen => ({
  type: UIActions.setProviderMenuOpen,
  payload: value,
})
